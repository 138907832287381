import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { folderStatuses } from '../../utils/constants/tagColors';
import usePatientContext from './PatientContext';

export const useColumns = (patients) => {
  const { t } = useTranslation();
  const { enums } = usePatientContext();

  return [
    {
      title: t('patients.columns.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('patients.columns.invoice_folder_status'),
      key: 'invoice_folder_status',
      dataIndex: ['invoice_folder_status'],
      render: (invoice_folder_status) =>
        invoice_folder_status ? (
          <Tag color={folderStatuses[invoice_folder_status]}>
            {t(`patients.tags.${invoice_folder_status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: false,
      filters: enums?.folderStatuses?.map((f) => ({
        text: t(`patients.tags.${f}`),
        value: f
      })),
      onFilter: (value, record) => record.invoice_folder_status === value
    },
    {
      title: t('patients.columns.folder_status'),
      key: 'folder_status',
      dataIndex: ['folder_status'],
      render: (folder_status) =>
        folder_status ? (
          <Tag color={folderStatuses[folder_status]}>
            {t(`patients.tags.${folder_status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: false,
      filters: enums?.folderStatuses?.map((f) => ({
        text: t(`patients.tags.${f}`),
        value: f
      }))
    },
    {
      title: t('patients.columns.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('patients.columns.usage_name'),
      key: 'usage_name',
      dataIndex: 'usage_name',
      sorter: true,
      render: (usage_name) => usage_name || '-'
    },
    {
      title: t('patients.columns.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('patients.columns.social_security_number'),
      key: 'social_security_number',
      dataIndex: 'social_security_number',
      sorter: true
    },
    {
      title: t('patients.columns.center_name'),
      key: 'center.center_name',
      dataIndex: 'center',
      render: (center) => center?.center_name || '-',
      filterMode: 'tree',
      filters: patients
        ?.filter((patient) => patient.center && patient.center.center_name)
        .reduce((acc, patient) => {
          if (!acc.some((item) => item.value === patient.center.center_name)) {
            acc.push({
              text: patient.center.center_name,
              value: patient.center.center_name
            });
          }
          return acc;
        }, []),
      onFilter: (value, record) => record.center?.center_name === value
    },
    {
      title: t('patients.columns.soldto'),
      key: 'center.soldto',
      dataIndex: 'center',
      render: (center) => center?.soldto || '-',
      filterMode: 'tree',
      filters: patients
        ?.filter((patient) => patient.center && patient.center.soldto)
        .reduce((acc, patient) => {
          if (!acc.some((item) => item.value === patient.center.soldto)) {
            acc.push({
              text: patient.center.soldto,
              value: patient.center.soldto
            });
          }
          return acc;
        }, []),
      onFilter: (value, record) => record.center?.soldto === value
    },
    {
      title: t('patients.columns.medical_device_serial_number'),
      key: 'medical_device_serial_number',
      dataIndex: 'medical_device_serial_number',
      sorter: true
    },
    {
      title: t('patients.columns.city_center'),
      key: 'center.address.city',
      dataIndex: 'center',
      render: (center) => (center?.address?.city ? center?.address?.city : '-'),
      sorter: true,
      filterMode: 'tree',
      filters: patients
        ?.filter((patient) => patient?.center && patient?.center?.address?.city)
        .reduce((acc, patient) => {
          if (
            !acc.some((item) => item.value === patient?.center?.address?.city)
          ) {
            acc.push({
              text: patient.center.address.city,
              value: patient.center.address.city
            });
          }
          return acc;
        }, []),
      onFilter: (value, record) => record.center?.address?.city === value
    }
  ];
};
