import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Drawer,
  Avatar,
  Form,
  Input,
  Typography,
  Popconfirm
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';

export const CommentsDrawer = ({
  title,
  onClose,
  open,
  size,
  updateResource,
  deleteResource,
  handleEditValidateClick,
  form,
  formEditComment,
  models
}) => {
  const { TextArea } = Input;
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { Text } = Typography;
  const [editingKey, setEditingKey] = useState('');

  // Met à jour le contenu lors de l'activation de l'édition
  useEffect(() => {
    if (editingKey && models?.comments) {
      const commentToEdit = models.comments.find(
        (comment) => comment._id === editingKey
      );
      if (commentToEdit) {
        formEditComment.setFieldsValue({ content: commentToEdit.content });
      }
    }
  }, [editingKey, formEditComment, models?.comments]);

  const displayDate = (dateString) => {
    if (!dateString) return '-';
    const dateObject = new Date(dateString);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return dateObject.toLocaleDateString(undefined, options);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      form.submit();
    }
  };

  const handleKeyPressEditComment = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      formEditComment.submit();
    }
  };

  const handleEditSubmit = (values) => {
    handleEditValidateClick(values, editingKey);
    setEditingKey('');
  };

  const handleDeleteComment = (id) => {
    deleteResource(id);
    setEditingKey('');
  };

  return (
    <Drawer
      title={title}
      onClose={onClose}
      open={open}
      size={size}
      footer={
        <Row gutter={[16, 16]}>
          <Col span={2}>
            <Avatar
              size={32}
              style={{ backgroundColor: '#87d068', verticalAlign: 'top' }}
            >
              {user.last_name.charAt(0).toUpperCase()}
            </Avatar>
          </Col>
          <Col span={22}>
            <Form
              name="add_comment_show_prescribers"
              onFinish={updateResource}
              form={form}
              onKeyDown={handleKeyPress}
              initialValues={{ comment: '' }}
            >
              <Form.Item name="comment">
                <TextArea
                  style={{ resize: 'none' }}
                  rows={3}
                  placeholder={t('prescribers.drawer.addComment.placeholder')}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                {`${t('prescribers.drawer.addComment.buttonAdd')} `}
              </Button>
            </Form>
          </Col>
        </Row>
      }
    >
      {(models?.comments || []).map((comment) => (
        <div key={comment?._id}>
          <Row gutter={[16, 16]}>
            <Col span={2}>
              <Avatar
                size={32}
                style={{ backgroundColor: '#87d068', verticalAlign: 'top' }}
              >
                {comment?.author?.last_name.charAt(0).toUpperCase()}
              </Avatar>
            </Col>
            <Col span={22}>
              <Form
                name={`edit_comment_${comment._id}`}
                onFinish={handleEditSubmit}
                form={formEditComment}
                onKeyDown={handleKeyPressEditComment}
              >
                {editingKey === comment?._id ? (
                  <>
                    <Form.Item name="content" style={{ marginBottom: 0 }}>
                      <TextArea rows={3} />
                    </Form.Item>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Button
                        icon={<CheckOutlined />}
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 8 }}
                      />
                      <Button
                        icon={<CloseOutlined />}
                        onClick={() => setEditingKey('')}
                        danger
                      />
                    </div>
                  </>
                ) : (
                  <Text>
                    {comment?.author?.last_name || '-'}{' '}
                    {comment?.author?.first_name || '-'}{' '}
                    {displayDate(comment?.date)}
                    <p>{comment?.content}</p>
                  </Text>
                )}
                {editingKey !== comment?._id && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      disabled={!!editingKey}
                      icon={<EditOutlined />}
                      onClick={() => setEditingKey(comment?._id)}
                      style={{ marginRight: 8 }}
                    />

                    <Button
                      type="link"
                      disabled={!!editingKey}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Popconfirm
                        title={t('settings.messages.delete-line-confirm')}
                        onConfirm={() => handleDeleteComment(comment?._id)}
                        placement="topRight"
                      >
                        <DeleteOutlined
                          type="delete"
                          style={{ color: 'var(--errorColor)' }}
                        />
                      </Popconfirm>
                    </Button>
                  </div>
                )}
              </Form>
            </Col>
          </Row>
        </div>
      ))}
    </Drawer>
  );
};

CommentsDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  updateResource: PropTypes.func.isRequired,
  deleteResource: PropTypes.func.isRequired,
  handleEditValidateClick: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    submit: PropTypes.func
  }).isRequired,
  models: PropTypes.shape({
    comments: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        author: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string
        }),
        content: PropTypes.string,
        date: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  formEditComment: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    submit: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};
