import { useState } from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { FolderStatusStats } from './FolderStatusStats';
import { useFormatter } from '../../utils/exportFormatter';
import usePatientContext from './PatientContext';

/**
 * Component for displaying a list of patients.
 * @returns {JSX.Element} - JSX for displaying a list of patients.
 */
export const ListPatients = () => {
  const { patients } = usePatientContext();
  const columns = useColumns(patients);
  const { formattedData } = useFormatter();
  const { t } = useTranslation();

  const [totals, setTotals] = useState([]);
  const [activePatient, setActivePatient] = useState(true);

  return (
    <ListResource
      resourceName="patients"
      columns={columns}
      populate="center,prescriber,payingagency_amo,payingagency_amc"
      headers={headers}
      resourceModelName="patients"
      setTotals={setTotals}
      formatter={formattedData}
      // eslint-disable-next-line react/no-children-prop
      children={<FolderStatusStats totals={totals} />}
      extraQuery={`status=${activePatient ? 'ACTIVE' : 'INACTIVE'}`}
      extraButtons={
        <Switch
          onChange={() => setActivePatient(!activePatient)}
          checkedChildren={t(`patients.status.${activePatient}`)}
          unCheckedChildren={t(`patients.status.${activePatient}`)}
          style={{ marginRight: '20px' }}
        />
      }
    />
  );
};
